<template>
    <app-autocomplete
        v-model="valueLocal.value"
        :label="valueLocal.label"
        :filter="filter"
        :schema="getSchema(valueLocal.pascalPlural)" />
</template>

<script>
export default {
    components: {
        appAutocomplete: () => import('../components/AppAutocomplete')
    },
    props: {
        value: {
            type: Object,
            default: null
        },
        filter: {
            type: Object,
            default: null
        }
    },
    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    }
}
</script>
